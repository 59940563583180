export const COLOR_PRIMARY = '#016096'
export const COLOR_SECONDARY = ''
export const COLOR_ACTION = '#6bc047'

export const COLOR_PRESENT = '#00B716'
export const COLOR_PRESENT_BORDER = 'black'
export const COLOR_ABSENT = '#3E007D'
export const COLOR_ABSENT_BORDER = '#3E007D'
export const COLOR_ABSENT_EX = '#AE5FFF'
export const COLOR_ABSENT_EX_BORDER = 'black'
export const COLOR_CLASS_PERIOD = '#000000'
export const COLOR_OUT = '#FBC02D'
export const COLOR_OUT_BORDER = '#FBC02D'
export const COLOR_OUT_OF_BUILDING = '#FFAB91'
export const COLOR_OUT_OF_BUILDING_BORDER = '#FFAB91'
export const COLOR_REPORT = '#3F654E'
export const COLOR_REPORT_BORDER = '#3F654E'
export const COLOR_TARDY = '#0197D7'
export const COLOR_TARDY_BORDER = 'black'
export const COLOR_TARDY_EX = '#67D2FF'
export const COLOR_TARDY_EX_BORDER = 'black'
export const COLOR_UNKNOWN = '#020202'
export const COLOR_UNKNOWN_BORDER = 'black'
export const COLOR_SKYWARD_CUSTOM = '#ff8000'
export const COLOR_SKYWARD_CUSTOM_BORDER = 'black'
export const COLOR_ON_SITE = '#95FCA2'


export const ATTENDANCE_VIEW_WEEK = 0
export const ATTENDANCE_VIEW_LIST = 1


export const ACTIVITY_KIOSK_DISABLED = 'ACTIVITY_KIOSK_DISABLED'
export const ACTIVITY_WAITING_FOR_LOCATION = 'ACTIVITY_WAITING_FOR_LOCATION'

export const ACTIVITY_ACCEPTING_ID_CODE = 'ACTIVITY_ACCEPTING_ID_CODE'
export const ACTIVITY_WAITING_TO_SEND = 'ACTIVITY_WAITING_TO_SEND'

export const ACTIVITY_PREVIOUS_ACTIVITY = 'ACTIVITY_PREVIOUS_ACTIVITY'
export const ACTIVITY_SENDING_ATTENDANCE = 'ACTIVITY_SENDING_ATTENDANCE'
export const ACTIVITY_CHOOSING_ATTENDANCE_TYPE = 'ACTIVITY_CHOOSING_ATTENDANCE_TYPE'

export const API_NEXT_ACTION_PRINT_SLIP = 'API_NEXT_ACTION_PRINT_SLIP'
export const API_NEXT_ACTION_SHOW_LARGE_TEXT = 'API_NEXT_ACTION_SHOW_LARGE_TEXT'

export const AT_TYPE_ID_NOT_SET = 0
export const AT_REASON_ID_NOT_SET = 0
export const AT_TYPE_ID_BELL_RULES = 1
export const AT_TYPE_ID_OUT = 2
export const AT_TYPE_ID_OUT_OF_BUILDING = 10
export const AT_TYPE_ID_PRESENT = 3
export const AT_TYPE_ID_REPORT = 4
export const AT_TYPE_ID_TARDY = 5
export const AT_TYPE_ID_TARDY_EXCUSED = 6
export const AT_TYPE_ID_ABSENT = 7
export const AT_TYPE_ID_ABSENT_EXCUSED = 8
export const AT_TYPE_ID_UNKNOWN = 9
export const AT_TYPE_ID_SKYWARD_CUSTOM = -2 //this is negative because it is not in database. It is a virtual id of all other at_types where id > 10
export const AT_TYPE_ID_ON_SITE = -1 //11 is already taken in production databases

export const AT_TYPE_NAME_ABSENT = 'ABSENT'
export const AT_TYPE_NAME_ABSENT_EXCUSED = 'ABSENT_EXCUSED'
export const AT_TYPE_NAME_TARDY = 'TARDY'
export const AT_TYPE_NAME_TARDY_EXCUSED = 'TARDY_EXCUSED'

/**
 * This should not be used for display!
 * @type {({name: string, id: number}|{name: string, id: number}|{name: string, id: number}|{name: string, id: number})[]}
 */
export const negativeAttendanceTypes = [
  {
    name:AT_TYPE_NAME_TARDY,
    id:AT_TYPE_ID_TARDY
  },
  {
    name:AT_TYPE_NAME_TARDY_EXCUSED,
    id:AT_TYPE_ID_TARDY_EXCUSED
  },
  {
    name:AT_TYPE_NAME_ABSENT,
    id:AT_TYPE_ID_ABSENT
  },
  {
    name:AT_TYPE_NAME_ABSENT_EXCUSED,
    id:AT_TYPE_ID_ABSENT_EXCUSED
  },
]


export const CHECKIN_FLOW_STANDARD = 'CHECKIN_FLOW_STANDARD'
export const CHECKIN_FLOW_CHOOSER = 'CHECKIN_FLOW_CHOOSER' //browser only constant
export const CHECKIN_FLOW_CHOOSER_FAST = 'CHECKIN_FLOW_CHOOSER_FAST'
export const CHECKIN_FLOW_CHOOSER_SLOW = 'CHECKIN_FLOW_CHOOSER_SLOW'
export const CHECKIN_FLOW_INSTANT = 'CHECKIN_FLOW_INSTANT' //browser only constant
export const CHECKIN_FLOW_INSTANT_FAST = 'CHECKIN_FLOW_INSTANT_FAST'
export const CHECKIN_FLOW_INSTANT_SLOW ='CHECKIN_FLOW_INSTANT_SLOW'
export const kioskCheckinFlowModes = [
  {
    id: CHECKIN_FLOW_STANDARD,
    displayName: 'Standard',
  },
  {
    id: CHECKIN_FLOW_CHOOSER_FAST,
    displayName: 'Chooser',
  },
  {
    id: CHECKIN_FLOW_INSTANT_FAST,
    displayName: 'Instant',
  },
  {
    id: CHECKIN_FLOW_CHOOSER_SLOW,
    displayName: 'Chooser-pause',
  },
  {
    id: CHECKIN_FLOW_INSTANT_SLOW,
    displayName: 'Instant-pause',
  },
]

export const DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS = 10000
export const DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS = 1000
export const DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS_LONG = 3500
export const DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_INFO = 2000
export const DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_ERROR = 3500
export const DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_ERROR_LONG = 5000
export const DEFAULT_SNACK_MESSAGE_AUTO_HIDE_NEVER = null

export const CLIENT_ACTION_SUICIDE = 'SUICIDE'
export const CLIENT_ACTION_GO_TO_INSTALL = 'goToInstall'

export const KIOSK_ATTENDANCE_DIRECTION_IN = 'in'
export const KIOSK_ATTENDANCE_DIRECTION_OUT = 'out'
export const KIOSK_ATTENDANCE_DIRECTION_MULTI = 'multi'

export const KIOSK_CAMERA_POLL_PAUSE_AFTER_ID_CODE_RECOGNITION_MS = 3000
export const KIOSK_CAMERA_PAUSE_AFTER_ID_CODE_RECOGNITION_MS = 1000
export const KIOSK_CAMERA_CLEAR_BOX_PATTERN_DELAY_MS = 250


export const KIOSK_TYPE_WEB = 'kiosk_type_web'
export const KIOSK_TYPE_PPK = 'kiosk_type_ppk'
export const kioskTypes = [
  KIOSK_TYPE_WEB,
  KIOSK_TYPE_PPK
]

export const KIOSK_UI_FAST_DELAY_MS = 100
export const KIOSK_UI_SLOW_DELAY_MS = 1000
export const KIOSK_UI_GLACIAL_DELAY_MS = 3000


export const E_KEY_BACKSPACE = 'Backspace'
export const E_KEY_ENTER = 'Enter'
export const E_KEY_REPORT = '5'
export const E_KEY_OUT = '0'
export const E_KEY_IN = '8'
export const E_KEY_ATTENDANCE_POLICE = '2'

export const FORMAT_AMPM = 'hh:mma'
export const FORMAT_YMD = 'YYYY-MM-DD'
export const FORMAT_AMPM_YMD = 'hh:mm A YYYY-MM-DD'
export const FORMAT_WEEKDAY = 'dddd'

export const ID_ZERO = 0

export const SEARCH_CONTEXT_LOCATIONS_PAGE = 'SEARCH_CONTEXT_LOCATIONS_PAGE'
export const SEARCH_CONTEXT_MESSAGING_PAGE = 'SEARCH_CONTEXT_MESSAGING_PAGE'
export const SEARCH_CONTEXT_USER_ACCOUNT_PAGE = 'SEARCH_CONTEXT_USER_ACCOUNT_PAGE'
export const SEARCH_CONTEXT_AT_USER_LIST_PAGE = 'SEARCH_CONTEXT_AT_USER_LIST_PAGE'
export const SEARCH_CONTEXT_AT_USER_LOCATOR_PAGE = 'SEARCH_CONTEXT_AT_USER_LOCATOR_PAGE'

export const KDCL_LOCATION = 'KDCL_LOCATION'
export const KDCL_FACILITY = 'KDCL_FACILITY'
export const KDCL_BUILDING = 'KDCL_BUILDING'
export const KDCL_ROOT = 'KDCL_ROOT'
export const KDCL_INSTANCE = 'KDCL_INSTANCE' //The prefix on this one is a bit of a misnomer, as when this is seen, the thing being edited is an actual kiosk.


export const PA_MODE_ALL = 'PA_MODE_ALL'
export const PA_MODE_OFF = 'PA_MODE_OFF'
export const PA_MODE_PER_PERIOD = 'PA_MODE_PER_PERIOD'

export const paModeList = [
  {
    displayName:'All periods',
    val:PA_MODE_ALL
  },
  {
    displayName:'Per period',
    val:PA_MODE_PER_PERIOD
  },
  {
    displayName:'Off',
    val:PA_MODE_OFF
  },
]

export const rStudentPasscode = /^([0-9]+|[a-z]+|[A-Z]+)+$/
export const rStudentPasscodeChar = /^([0-9]|[a-z]|[A-Z])$/
export const rStudentPasscodeXmit =new RegExp('^([0-9]+|[a-z]+|[A-Z]+)+(\\\n)?(\\' + E_KEY_IN +')?$')
export const rYMD = /^[0-9]{4}-(0[0-9]|1(1|2))-[0-9]{2}$/

export const DEMO_KEYPAD_REPLACE = 'DEMO_KEYPAD_REPLACE'
export const DEMO_KEYPAD_ENABLE_DISABLE = 'DEMO_KEYPAD_ENABLE_DISABLE'

export const STATUS_IN_PROGRESS = 'STATUS_IN_PROGRESS'

export const STATUS_MESSAGE_TYPE_SUCCESS = 'success'
export const STATUS_MESSAGE_TYPE_INFO = 'info'
export const STATUS_MESSAGE_TYPE_ERROR = 'error'
export const STATUS_MESSAGE_TYPE_WARNING = 'warning'

export const SECONDS_KIOSK_AUTO_LOCK = 120
export const SECONDS_NONE = 0
export const SECONDS_HOUR = 3600
export const SECONDS_DAY = 86400
export const SECONDS_WEEK = 604800
export const SECONDS_FOUR_WEEKS = 2419200
export const SECONDS_YEAR = 31536000


export const SECURITY_ROLE_SUPER_ADMIN = 'super_admin'
export const SECURITY_ROLE_ADMIN = 'admin'
export const SECURITY_ROLE_STAFF = 'staff'


export const SYNC_RUN_STATUS_STARTING = 'SYNC_RUN_STATUS_STARTING'
export const SYNC_RUN_STATUS_STOPPED = 'SYNC_RUN_STATUS_STOPPED'
export const SYNC_RUN_STATUS_WAITING = 'SYNC_RUN_STATUS_WAITING'
export const SYNC_RUN_STATUS_FAILED_STOPPED = 'SYNC_RUN_STATUS_FAILED_STOPPED'
export const SYNC_RUN_STATUS_FAILED_WAITING = 'SYNC_RUN_STATUS_FAILED_WAITING'
export const SYNC_RUN_STATUS_RUNNING = 'SYNC_RUN_STATUS_RUNNING'
export const SYNC_RUN_STATUS_DONE = 'SYNC_RUN_STATUS_DONE'
export const SYNC_RUN_STATUS_ERROR = 'SYNC_RUN_STATUS_ERROR'
export const SYNC_RUN_STATUS_STOPPED_WAITING = 'SYNC_RUN_STATUS_STOPPED_WAITING'

export const syncRunStatusList = [
  {
    displayName: 'Starting',
    val: SYNC_RUN_STATUS_STARTING
  },
  {
    displayName: 'Stopped',
    val: SYNC_RUN_STATUS_STOPPED
  },
  {
    displayName: 'Waiting',
    val: SYNC_RUN_STATUS_WAITING
  },
  {
    displayName: 'Error/Stopped',
    val: SYNC_RUN_STATUS_FAILED_STOPPED
  },
  {
    displayName: 'Error/Waiting',
    val: SYNC_RUN_STATUS_FAILED_WAITING
  },
  {
    displayName: 'Running',
    val: SYNC_RUN_STATUS_RUNNING
  },
  {
    displayName: 'Done',
    val: SYNC_RUN_STATUS_DONE
  },
  {
    displayName: 'Error',
    val: SYNC_RUN_STATUS_ERROR
  },
  {
    displayName: 'Stopped/Waiting',
    val: SYNC_RUN_STATUS_STOPPED_WAITING
  },
]

export const syncRunStatusDisplayNameByName = syncRunStatusList.reduce((acc, item)=> {
  acc[item.val] = item.displayName
  return acc
})

export const TYPE_YMD = 'TYPE_YMD'
export const TYPE_INT = 'TYPE_INT'

export const SYNC_STATE_ERROR = 'SYNC_STATE_ERROR'
export const SYNC_STATE_ERROR_PUSH = 'SYNC_STATE_ERROR_PUSH'
export const SYNC_STATE_ERROR_PULL = 'SYNC_STATE_ERROR_PULL'
export const SYNC_STATE_NEEDS_SYNC = 'SYNC_STATE_NEEDS_SYNC'
export const SYNC_STATE_NEEDS_DELETE = 'SYNC_STATE_NEEDS_DELETE'
export const SYNC_STATE_NOT_SYNCABLE = 'SYNC_STATE_NOT_SYNCABLE'
export const SYNC_STATE_SYNCED_BOTH = 'SYNC_STATE_SYNCED_BOTH'
export const SYNC_STATE_SYNCED_PUSH = 'SYNC_STATE_SYNCED_PUSH'
export const SYNC_STATE_SYNCED_PULL = 'SYNC_STATE_SYNCED_PULL'
export const SYNC_STATE_SYNCING = 'SYNC_STATE_SYNCING'

export const URL_API_ROOT = '/xhr_is_magic'
export const URL_API_ADMIN_ROOT = `${URL_API_ROOT}/admin`

export const URL_FRAG_FACILITY_ID = '/:facilityId?'
export const URL_FRAG_ITEM_ID = '/:itemId'
export const URL_FRAG_ITEM_ID_OPTIONAL = '/:itemId?'
export const URL_ADMIN_ROOT = '/mgmt'
export const URL_ADMIN_FACILITY_ROOT = `${URL_ADMIN_ROOT}${URL_FRAG_FACILITY_ID}`

export const URL_BELL_SCHEDULE = `${URL_ADMIN_FACILITY_ROOT}/period_schedule`

export const URL_LOCATION = `${URL_ADMIN_FACILITY_ROOT}/locations`
export const URL_LOCATION_DETAIL = `${URL_ADMIN_FACILITY_ROOT}/location/:itemId`

export const URL_HOME = `${URL_ADMIN_FACILITY_ROOT}/home`
export const URL_HOME_ROUTE = `${URL_ADMIN_FACILITY_ROOT}/home${URL_FRAG_ITEM_ID}?` //in this case item id is a YMD

export const URL_MANAGE_STUDENTS = `${URL_ADMIN_FACILITY_ROOT}/students`
export const URL_MANAGE_STUDENT_DETAIL = `${URL_ADMIN_FACILITY_ROOT}/student/:itemId`
export const URL_MANAGE_STUDENT_DETAIL_WEEK = `${URL_MANAGE_STUDENT_DETAIL}/week`

export const URL_LOCATE_STUDENTS = `${URL_ADMIN_FACILITY_ROOT}/locator`

export const URL_ACCOUNT_SETTINGS = `${URL_ADMIN_ROOT}/account`

export const URL_MANAGE_USERS = `${URL_ADMIN_FACILITY_ROOT}/users`

export const URL_MESSAGING = `${URL_ADMIN_FACILITY_ROOT}/messaging`
export const URL_REPORTS = `${URL_ADMIN_FACILITY_ROOT}/report`
export const URL_SETTINGS = `${URL_ADMIN_FACILITY_ROOT}/settings`

export const URL_SEMESTER_RULES = `${URL_ADMIN_FACILITY_ROOT}/semester_rules`

export const VIEW_ATTENDANCE_POLICY = `VIEW_ATTENDANCE_POLICY`

export const WEEKDAY_NUMBERS = [0, 1, 2, 3, 4, 5, 6]
/**
 * The index number corresponds to the correct weekday number
 * @type {string[]}
 */
export const WEEKDAYS = ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY']

export const WORKER_JOB_ACTION_START = 'WORKER_JOB_ACTION_START'
export const WORKER_JOB_ACTION_STOP = 'WORKER_JOB_ACTION_STOP'


export const ON_FAILED_RETRY = 'ON_FAILED_RETRY'
export const ON_FAILED_CONTINUE = 'ON_FAILED_CONTINUE'
export const ON_FAILED_STOP = 'ON_FAILED_STOP'


export const DELETE_ALL_CHARS = 'DELETE_ALL_CHARS'

export const TXT_EN_ALL_FACILITIES = 'All Entities'
export const TXT_EN_YOUR_FACILITIES = 'Your Entites'
export const TXT_EN_ENTITY_AGNOSTIC_PAGE = 'This page is entity agnostic.'

export const WORKER_JOB_NAME_COORDINATOR = 'WORKER_JOB_NAME_COORDINATOR'
export const WORKER_JOB_NAME_SYNC_ENTITIES = 'WORKER_JOB_NAME_SYNC_ENTITIES'
export const WORKER_JOB_NAME_SYNC_SCHOOL_YEARS = 'WORKER_JOB_NAME_SYNC_SCHOOL_YEARS'
export const WORKER_JOB_NAME_SYNC_BUILDINGS = 'WORKER_JOB_NAME_SYNC_BUILDINGS'
export const WORKER_JOB_NAME_SYNC_ROOMS = 'WORKER_JOB_NAME_SYNC_ROOMS'
export const WORKER_JOB_NAME_SYNC_SCHOOLS = 'WORKER_JOB_NAME_SYNC_SCHOOLS'
export const WORKER_JOB_NAME_SYNC_SEMESTERS = 'WORKER_JOB_NAME_SYNC_SEMESTERS'
export const WORKER_JOB_NAME_SYNC_STUDENTS = 'WORKER_JOB_NAME_SYNC_STUDENTS'
export const WORKER_JOB_NAME_SYNC_STUDENT_SCHEDULES = 'WORKER_JOB_NAME_SYNC_STUDENT_SCHEDULES'
export const WORKER_JOB_NAME_SYNC_ATTENDANCE = 'WORKER_JOB_NAME_SYNC_ATTENDANCE'
export const WORKER_JOB_NAME_SYNC_ATTENDANCE_TYPES = 'WORKER_JOB_NAME_SYNC_ATTENDANCE_TYPES'

export const WORKER_JOB_COORDINATOR_LONG_RUN_SECONDS = 15

export const FACILITY_NOT_SET = {
  id:-1,
  name:"notset",
  displayName:'not set'
}

export const LOCATION_NOT_SET = {
  id: -1,
  name: "notset",
  displayName: 'not set',
  kiosks: []
}

export const securityRoles = [
  {
    displayName: 'Admin',
    value: SECURITY_ROLE_ADMIN
  },
  {
    displayName: 'Staff',
    value: SECURITY_ROLE_STAFF
  }
]

export const fieldNames = {
  period_number: 'periodNumber',
  display_code: 'vendorDisplayPeriodCode',
  display_name: 'displayName',
  course_description: 'courseDescription',
  location: 'location',
  facility: 'facility',
  teacher: 'teacher',
}

export const SCHED_DISP_SETTINGS = {
  badge: ['period_number', 'display_code', 'display_name', 'course_description', 'location', 'facility', 'teacher'],
  tooltip: ['period_number', 'display_code', 'display_name', 'course_description', 'location', 'facility', 'teacher'],
}

export const atr = {
  canPrint:'canPrint',
  enableGps:'enableGps',
  acceptKeyboardInput:'acceptKeyboardInput',
  requestSendReport:'requestSendReport',
  enableKeyPad:'enableKeyPad',
  atType:'atType',
  atTypeId:'atTypeId', //for apis primarily
  attendanceDirection:'attendanceDirection',
  isLocked:'isLocked',
  isDeleted:'isDeleted',
  isEnabled:'isEnabled',
  enableEmailSlip: 'enableEmailSlip'
}

export const secondsIn = [
  {val: SECONDS_NONE, text: 'Never'},
  {val: SECONDS_HOUR, text: '1 hour'},
  {val: SECONDS_DAY, text:'1 day'},
  {val: SECONDS_WEEK, text:'1 week'},
  {val: SECONDS_FOUR_WEEKS, text:'4 weeks'},
  {val: SECONDS_YEAR, text:'1 year'}
  ]

export const yesNoBoolList = [
  {val: true, displayName:'Yes'},
  {val: false, displayName:'No'},
]

export const onFailedActions = [
  {val: ON_FAILED_RETRY, displayName: 'Retry immediately'},
  {val: ON_FAILED_CONTINUE, displayName: 'Retry next sync'},
  {val: ON_FAILED_STOP, displayName: 'Stop syncing'}
  ]

export const cameraNumWorkers = [
  {
    displayName: 0,
    val: 0
  },
  {
    displayName: 1,
    val: 1
  },
  {
    displayName: 2,
    val: 2
  },
  {
    displayName: 4,
    val: 4
  },
  {
    displayName: 8,
    val: 8
  }
]

export const cameraInputStreamResolution = [
  {
    displayName: "320px",
    val: "320x240"
  },
  {
    displayName: "640px",
    val: "640x480"
  },
  {
    displayName: "800px",
    val: "800x600"
  },
  {
    displayName: "1280px",
    val: "1280x720"
  },
  {
    displayName: "1600px",
    val: "1600x960"
  },
  {
    displayName: "1920px",
    val: "1920x1080"
  }
]

export const cameraLocatorPatchSizeList = [
  {
    displayName: "x-small",
    val: "x-small"
  },
  {
    displayName: "small",
    val: "small"
  },
  {
    displayName: "medium",
    val: "medium"
  },
  {
    displayName: "large",
    val: "large"
  },
  {
    displayName: "x-large",
    val: "x-large"
  }
]

export const barcodeTypeList = [
  {
    displayName: "Code 128",
    val: "code_128_reader"
  },
  {
    displayName: "Code 39",
    val: "code_39_reader"
  },
  {
    displayName: "Code 39 VIN",
    val: "code_39_vin_reader"
  },
  {
    displayName: "EAN",
    val: "ean_reader"
  },
  {
    displayName: "EAN-extended",
    val: "ean_extended_reader"
  },
  {
    displayName: "EAN-8",
    val: "ean_8_reader"
  },
  {
    displayName: "UPC",
    val: "upc_reader"
  },
  {
    displayName: "UPC-E",
    val: "upc_e_reader"
  },
  {
    displayName: "Codabar",
    val: "codabar_reader"
  },
  {
    displayName: "I2of5",
    val: "i2of5_reader"
  },
  {
    displayName: "Standard 2 of 5",
    val: "2of5_reader"
  },
  {
    displayName: "Code 93",
    val: "code_93_reader"
  }
]

export const timezoneNotSet = {val:'none', displayName:'Not Set'}

export const TIMEZONES = [
  {
    "displayName":"Alaskan Standard Time",
    "val":"America/Anchorage",
    "value": "Alaskan Standard Time",
    "abbr": "AKDT",
    "offset": -8,
    "isdst": true,
    "text": "(UTC-09:00) Alaska",
    "utc": [
      "America/Anchorage",
      "America/Juneau",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat"
    ]
  },
  {
    "displayName":"Pacific Daylight Time",
    "val":"America/Los_Angeles",
    "value": "Pacific Daylight Time",
    "abbr": "PDT",
    "offset": -7,
    "isdst": true,
    "text": "(UTC-07:00) Pacific Time (US & Canada)",
    "utc": [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse"
    ]
  },
  {

    "displayName":"Pacific Standard Time",
    "val":"America/Vancouver",
    "value": "Pacific Standard Time",
    "abbr": "PST",
    "offset": -8,
    "isdst": false,
    "text": "(UTC-08:00) Pacific Time (US & Canada)",
    "utc": [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse",
      "PST8PDT"
    ]
  },
  {

    "displayName":"Arizona",
    "val":"America/Phoenix",
    "value": "US Mountain Standard Time",
    "abbr": "UMST",
    "offset": -7,
    "isdst": false,
    "text": "(UTC-07:00) Arizona",
    "utc": [
      "America/Creston",
      "America/Dawson_Creek",
      "America/Hermosillo",
      "America/Phoenix",
      "Etc/GMT+7"
    ]
  },
  {

    "displayName":"Mountain Standard Time",
    "val":"America/Denver",
    "value": "Mountain Standard Time",
    "abbr": "MDT",
    "offset": -6,
    "isdst": true,
    "text": "(UTC-07:00) Mountain Time (US & Canada)",
    "utc": [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Denver",
      "America/Edmonton",
      "America/Inuvik",
      "America/Ojinaga",
      "America/Yellowknife",
      "MST7MDT"
    ]
  },
  {
    "displayName":"Central Standard Time",
    "val":"America/Chicago",
    "value": "Central Standard Time",
    "abbr": "CDT",
    "offset": -5,
    "isdst": true,
    "text": "(UTC-06:00) Central Time (US & Canada)",
    "utc": [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Matamoros",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
      "CST6CDT"
    ]
  },
  {
    "displayName":"Eastern Standard Time",
    "val":"America/New_York",
    "value": "Eastern Standard Time",
    "abbr": "EDT",
    "offset": -4,
    "isdst": true,
    "text": "(UTC-05:00) Eastern Time (US & Canada)",
    "utc": [
      "America/Detroit",
      "America/Havana",
      "America/Indiana/Petersburg",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Kentucky/Monticello",
      "America/Louisville",
      "America/Montreal",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Thunder_Bay",
      "America/Toronto",
      "EST5EDT"
    ]
  }
]

export const AT_TYPE_IN = 'in'
export const AT_TYPE_OUT = 'out'
export const AT_TYPE_REPORT = 'report'
export const AT_KIOSK_DIRECTION_IN = AT_TYPE_IN
export const AT_KIOSK_DIRECTION_OUT = AT_TYPE_OUT
export const AT_KIOSK_DIRECTION_MULTI = 'multi'
export const AT_KIOSK_DIRECTION_NONE = 'none'

export const AT_RECORD_CREATED_WITH_KIOSK = 'AT_RECORD_CREATED_WITH_KIOSK'
export const AT_RECORD_CREATED_WITH_ADMIN = 'AT_RECORD_CREATED_WITH_ADMIN'
export const AT_RECORD_CREATED_WITH_AT_MONITOR = 'AT_RECORD_CREATED_WITH_AT_MONITOR'
export const AT_RECORD_CREATED_WITH_Q = 'AT_RECORD_CREATED_WITH_Q'
export const AT_RECORD_CREATED_WITH_OUT_PROPAGATION = 'AT_RECORD_CREATED_WITH_OUT_PROPAGATION'

export const CUSTOMIZE_FACILITIES_TITLE = 'CUSTOM ENTITY SETUP'

export const GENERIC_AT_TYPES_IN_DATABASE_COUNT = 10;
export const OVERRIDE_TYPE_ID_DEFAULT = 0;
export const OVERRIDE_TYPE_ID_CUSTOM_PERIOD_RULES = 1;
export const OVERRIDE_TYPE_ID_CUSTOM_SINGLE_ATTENDANCE = 2;

export const kioskFacilityAttendanceOverridesTypes = [
  {
    id: OVERRIDE_TYPE_ID_DEFAULT,
    displayName: 'Default',
    val:'Default',
  }, 
  {
    id: OVERRIDE_TYPE_ID_CUSTOM_PERIOD_RULES,
    displayName: 'Period Rules Custom',
    val: 'CustomPeriodRules',
  }, 
  {
    id: OVERRIDE_TYPE_ID_CUSTOM_SINGLE_ATTENDANCE,
    displayName: 'Single Attendance Type Custom',
    val: 'CustomSingleAttendanceType',
  }
];

export const SKYWARD_CUSTOM_AT_TYPE = {
  id: AT_TYPE_ID_SKYWARD_CUSTOM,
  name:"Specific",
  displayName: "Specific",
  description: "Specific",
  facilityId: 0,
  isKioskMode: false,
  kioskCanOverride: false
}

export const PIE_DISPLAY_ORDER = [
  'Absent', 
  'Absent-Excused', 
  'Tardy', 
  'Tardy-Excused', 
  'Present', 
  'Out-of-Building', 
  'Out', 
  'Specific',
  'Unknown'
];


export default {}

